.documents {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;

  .client-info {
    padding: 30px 60px 40px 71px;
    justify-content: space-between;
    width: 90%;
    max-width: 1295px;
    margin-bottom: 30px;
    box-sizing: border-box;

    .col {
      display: flex;
      flex-direction: column;

      .title {
        font-size: 22px;
        font-weight: 300;
        line-height: 1.45;
        color: var(--dusk-blue);
        margin-bottom: 18px;
      }

      .inputLabel {
        margin-bottom: 0;
      }

      .value {
        margin-bottom: 6px;
        font-size: 15px;
        line-height: 1.47;
        color: var(--dark-grey-blue);
      }

      .button {
        margin-top: 53px;
      }
    }
  }

  .page-title {
    width: 90%;
    max-width: 1295px;
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;
    color: var(--dusk-blue);
    margin-bottom: 30px;
  }

  .documents-column {
    width: 90%;
    max-width: 1295px;
    justify-content: space-between;

    .section {
      width: 24.5%;
      max-width: 315px;
      padding: 21px 27px 30px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;

      .title {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.4;
        color: var(--dusk-blue);
        margin-bottom: 18px;
      }

      .labels {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.13px;
        color: var(--cloudy-blue);
        text-transform: uppercase;
        padding-bottom: 7px;
        border-bottom: solid 3px var(--pale-grey);
        margin-bottom: 12px;
        justify-content: space-between;

        .date {
          margin-right: 30px;
        }
      }

      .document {
        justify-content: space-between;

        &:last-child {
          margin-bottom: 21px;
        }

        .name {
          font-size: 12px;
          line-height: 1.83;
          color: var(--medium-green);
        }

        .date {
          font-size: 12px;
          line-height: 1.83;
          color: var(--dark-grey-blue);
        }
      }

      .button {
        margin-top: auto;
      }
    }
  }
}