:root {
  --white: rgb(255, 255, 255);
  --dusk-blue: rgb(43, 56, 140);
  --clear-blue: rgb(46, 91, 255);
  --clear-blue-007: rgba(46, 91, 255, 0.07);
  --clear-blue-008: rgba(46, 91, 255, 0.08);
  --clear-blue-015: rgba(46, 91, 255, 0.15);
  --cloudy-blue: rgb(176, 186, 201);
  --dark-grey-blue: rgb(46, 56, 77);
  --medium-green: rgb(51, 172, 46);
  --pale-lilac: rgb(224, 231, 255);
  --pale-lilac-02: rgba(224, 231, 255, 0.2);
  --pale-grey: rgb(244, 246, 252);
  --pale-grey-01: rgba(244, 246, 252, 0.1);
  --bluey-grey: rgb(135, 152, 173);
  --cloudy-blue-two: rgb(191, 197, 210);
  --pale-grey-two: rgb(228, 232, 240);
  --black-07: rgba(0, 0, 0, 0.7);
  --bluegrey: rgb(128, 151, 177);
  --orangey-red: rgb(255, 61, 61);
}

body, textarea {
  margin: 0;
  font-family: "Rubik", sans-serif;
  min-height: 100%;
}

.notifications, .application-status, .clientInfo, .landing .right, .user-profile, .addNewAdvisor, .fast-navigation, .sidebar-list.shadow, .light-box, header, .button, .noselect {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -khtml-user-select: none;
  /* Konqueror HTML */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
}

.notifications .actions-list, .landing .right .card .list .children, .sidebar-list.shadow .sidebar, .light-box .shadow .box .children {
  padding-right: 5px;
}
.notifications .actions-list::-webkit-scrollbar, .landing .right .card .list .children::-webkit-scrollbar, .sidebar-list.shadow .sidebar::-webkit-scrollbar, .light-box .shadow .box .children::-webkit-scrollbar {
  width: 2px;
}
.notifications .actions-list::-webkit-scrollbar-track, .landing .right .card .list .children::-webkit-scrollbar-track, .sidebar-list.shadow .sidebar::-webkit-scrollbar-track, .light-box .shadow .box .children::-webkit-scrollbar-track {
  background: var(--white);
}
.notifications .actions-list::-webkit-scrollbar-thumb, .landing .right .card .list .children::-webkit-scrollbar-thumb, .sidebar-list.shadow .sidebar::-webkit-scrollbar-thumb, .light-box .shadow .box .children::-webkit-scrollbar-thumb {
  background: var(--bluey-grey);
}

#root {
  background-color: var(--pale-grey);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100%;
}

html,
body {
  width: 100%;
  height: 100%;
}

.Rectangle {
  width: 272px;
  height: 62px;
  border-radius: 5px;
  border: dashed 1.4px #b1b9d4;
  background-color: var(--pale-lilac-02);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--bluey-grey);
}

.dateEsign {
  font-family: "Rubik";
}

.button {
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  min-height: 40px;
  position: relative;
}
.button:hover {
  cursor: pointer;
}

header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: var(--dusk-blue);
  height: 10vh;
  max-height: 80px;
  justify-content: center;
  padding-right: 3.5vw;
  z-index: 9;
}
header .logo {
  margin-left: 1.9vw;
  margin-right: 5.4vw;
  height: 50px;
}
header .logo:hover {
  cursor: pointer;
}
header .link {
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.47px;
  white-space: nowrap;
}
header .link:hover {
  cursor: pointer;
}
header .link:nth-of-type(1) {
  margin-right: 1.8vw;
}
header .link:nth-of-type(2) {
  margin-right: 2.6vw;
}
header .link:nth-of-type(3) {
  margin-right: 2.5vw;
}
header .link:nth-of-type(4) {
  margin-right: 0.8vw;
}
header .bellContainer {
  width: 26px;
  height: 18.5px;
  position: relative;
  margin-right: 3.1vw;
}
header .bellContainer:hover {
  cursor: pointer;
}
header .bellContainer .bell {
  height: 2.5vh;
  margin-top: -1.5px;
}
header .bellContainer .numberHolder {
  position: absolute;
  top: -0.5vh;
  right: -0.2vw;
  width: 100%;
  height: 100%;
  max-width: 15px;
  max-height: 15px;
  border: solid 1px white;
  box-sizing: border-box;
  border-radius: 50%;
  background-color: var(--clear-blue);
  display: flex;
  align-items: center;
  justify-content: center;
}
header .bellContainer .numberHolder .number {
  font-size: 7px;
  letter-spacing: 1px;
  color: white;
}
header .searchContainer {
  width: 16vw;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  background-color: var(--pale-grey-01);
  margin-right: 6.1vw;
  padding-left: 13px;
  padding-right: 13px;
}
header .searchContainer .searchBar {
  background-color: transparent;
  border: none;
  color: white;
  outline: none;
  width: 11.9vw;
  min-height: 20px;
}
header .searchContainer .searchBar::placeholder {
  color: var(--bluey-grey);
  font-size: 12px;
}
header .searchContainer .searchIcon {
  margin-left: 17px;
}
header .searchContainer .searchIcon:hover {
  cursor: pointer;
}
header .profile {
  display: flex;
  align-items: center;
  margin-right: 1vw;
}
header .profile:hover {
  cursor: pointer;
}
header .profile .profileText {
  font-size: 14px;
  font-weight: 300;
  line-height: 1.5;
  letter-spacing: 0.5px;
  color: white;
  margin-right: 1.4vw;
  white-space: nowrap;
}
header .profile .avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-position: center;
  background-size: contain;
}
header .version_text {
  position: absolute;
  top: 5px;
  right: 5px;
  color: rgba(255, 255, 255, 0.3);
  font-size: 8pt;
}

.card {
  box-shadow: 0 10px 20px 0 var(--clear-blue-007);
  background-color: var(--white);
  border-radius: 1px;
  border: solid 1px var(--clear-blue-008);
}

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;
}
.inputContainer.radio > input:hover, .inputContainer.date > input:hover {
  cursor: pointer;
}
.inputContainer.radio > img, .inputContainer.date > img {
  top: 36px;
}
.inputContainer.date .box {
  width: 430px;
}
.inputContainer.date .arrow:hover {
  cursor: pointer;
}
.inputContainer.date .calendar {
  flex-wrap: wrap;
  justify-content: flex-start !important;
  margin-top: 15px;
}
.inputContainer.date .calendar .day {
  display: flex;
  width: 14.285%;
  height: 40px;
  justify-content: center;
  align-items: center;
}
.inputContainer.date .calendar .day.active {
  background-color: var(--clear-blue);
  color: var(--white);
}
.inputContainer.date .calendar .day:hover {
  cursor: pointer;
  background-color: var(--pale-lilac);
}
.inputContainer.date .calendar .day.nohover {
  cursor: default;
  background-color: var(--white);
}
.inputContainer .inputLabel {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
  margin-bottom: 7px;
  text-transform: uppercase;
}
.inputContainer .input {
  width: 380px;
  height: 38.1px;
  border-radius: 5px;
  border: solid 1px var(--pale-lilac);
  background-color: var(--pale-lilac-02);
  padding-left: 16px;
  padding-right: 22.8px;
  outline-color: var(--cloudy-blue);
  font-size: 15px;
  color: var(--dark-grey-blue);
  box-sizing: border-box;
}
.inputContainer .input:active, .inputContainer .input:focus {
  outline: none;
}
.inputContainer .input::placeholder {
  color: var(--cloudy-blue);
}
.inputContainer .input.multiSelect {
  cursor: pointer;
}
.inputContainer.correct .input {
  border-color: var(--medium-green);
}
.inputContainer > img {
  position: absolute;
  bottom: 14px;
  right: 17px;
  cursor: pointer;
}

.checkboxContainer {
  width: 380px;
  display: flex;
  justify-content: space-between;
}
.checkboxContainer .inputLabel {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.47;
  color: var(--dark-grey-blue);
}

.row {
  display: flex;
  flex-direction: row;
}

.light-box {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
}
.light-box .shadow {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--black-07);
  display: flex;
  justify-content: center;
  align-items: center;
}
.light-box .shadow .box {
  padding: 40px 25px 30px;
  min-width: 430px;
  min-height: 450px;
  box-sizing: border-box;
}
.light-box .shadow .box:hover {
  cursor: auto;
}
.light-box .shadow .box .title {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.14;
  margin-bottom: 25px;
  color: var(--dusk-blue);
  display: block;
  text-transform: capitalize;
}
.light-box .shadow .box .children {
  height: 300px;
  overflow: auto;
  margin-bottom: 13px;
}
.light-box .shadow .box .row {
  justify-content: space-between;
}

.checkbox {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.checkbox:hover {
  cursor: pointer;
}
.checkbox.active {
  background-color: var(--medium-green);
}
.checkbox.inactive {
  background-color: var(--cloudy-blue);
}

.multipleSelectItem {
  padding: 13px 0;
}
.multipleSelectItem:hover {
  cursor: pointer;
  background-color: var(--pale-lilac);
}
.multipleSelectItem .label {
  font-size: 18px;
  color: var(--dark-grey-blue);
}

.multipleRadioItem {
  justify-content: flex-start !important;
  align-items: center;
  padding: 13px 0;
}
.multipleRadioItem:hover {
  cursor: pointer;
  background-color: var(--pale-lilac);
}
.multipleRadioItem .radio {
  width: 22px;
  height: 22px;
  border-radius: 50%;
  margin-right: 11px;
  box-sizing: border-box;
}
.multipleRadioItem .radio.active {
  border: solid 4px var(--clear-blue);
  display: flex;
  justify-content: center;
  align-items: center;
}
.multipleRadioItem .radio.inactive {
  opacity: 0.18;
  border: solid 3px var(--bluegrey);
}
.multipleRadioItem .label {
  font-size: 18px;
  color: var(--dark-grey-blue);
  text-transform: capitalize;
}

.sidebar-list.shadow {
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  bottom: 0;
  height: 100%;
  background-color: var(--black-07);
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
}
.sidebar-list.shadow .sidebar {
  cursor: default;
  height: 100%;
  background-color: var(--white);
  width: 330px;
  overflow: auto;
}
.sidebar-list.shadow .sidebar > .row {
  justify-content: space-between;
  align-items: center;
  padding: 40px 24px;
}
.sidebar-list.shadow .sidebar > .row .title {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--dusk-blue);
}
.sidebar-list.shadow .sidebar .item {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid var(--clear-blue-008);
  padding: 0 24px;
  margin-bottom: 25px;
}
.sidebar-list.shadow .sidebar .item .name {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
}
.sidebar-list.shadow .sidebar .item .email {
  font-size: 12px;
  line-height: 1.83;
  color: var(--dusk-blue);
  margin-bottom: 5px;
}
.sidebar-list.shadow .sidebar .item .content {
  font-size: 15px;
  line-height: 1.47;
  color: var(--dark-grey-blue);
  margin-bottom: 20px;
}
.sidebar-list.shadow .sidebar .item > .row {
  justify-content: space-between;
  margin-bottom: 14px;
}
.sidebar-list.shadow .sidebar .item > .row .date {
  font-size: 12px;
  line-height: 1.83;
  color: var(--cloudy-blue-two);
}
.sidebar-list.shadow .sidebar .item > .row .open {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--clear-blue);
}
.sidebar-list.shadow .sidebar .item > .row .open:hover {
  cursor: pointer;
}

.search-light-box {
  width: 75.7%;
}
.search-light-box > .row {
  justify-content: flex-start !important;
}
.search-light-box > .row .button:first-child {
  margin-right: 20px;
}

.fast-navigation {
  min-height: 95px;
  align-items: center;
}
.fast-navigation .fast-navigation-item {
  align-items: center;
  margin: 0 30px;
}
.fast-navigation .fast-navigation-item:hover {
  cursor: pointer;
}
.fast-navigation .fast-navigation-item.active {
  opacity: 0.5;
}
.fast-navigation .fast-navigation-item .icon {
  margin-right: 15px;
}
.fast-navigation .fast-navigation-item .text {
  margin-right: 10px;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--dusk-blue);
}
.fast-navigation .fast-navigation-item .arrow {
  margin-top: 2px;
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--dusk-blue);
}

.checklist-item {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;
}
.checklist-item:last-child {
  margin-bottom: 30px;
}
.checklist-item label {
  font-size: 12px;
  color: var(--dark-grey-blue);
}
.checklist-item .checkbox {
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  opacity: 0.18;
  border-radius: 6px;
  border: solid 2px var(--bluegrey);
}
.checklist-item .checkbox.active {
  opacity: 1;
  border-color: var(--clear-blue);
  background-color: var(--clear-blue);
}
.checklist-item .checkbox img {
  height: 10px;
  width: 10px;
}

.loader {
  display: block;
  margin: 0 auto;
  width: 3vw;
}

.nothing_here {
  display: block;
  margin: 15px 26px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
}

.page-under-development {
  font-size: 40px;
  font-weight: 300;
  line-height: 0.8;
  color: var(--dusk-blue);
  margin: 75px auto;
}

.addNewAdvisor .input {
  width: 100%;
}

.office-input {
  width: 100%;
  margin-bottom: 12px;
}
.office-input > input.input {
  width: 100%;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 46.8px;
  height: 26px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.4;
  background-color: var(--bluey-grey);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: var(--white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  opacity: 1;
  background-color: var(--medium-green);
}

input:focus + .slider {
  box-shadow: 0 0 1px var(--medium-green);
}

input:checked + .slider:before {
  -webkit-transform: translateX(20px);
  -ms-transform: translateX(20px);
  transform: translateX(20px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.user-profile {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;
}
.user-profile .titleHolder {
  padding-top: 3vh;
  padding-bottom: 3vh;
  width: 75%;
}
.user-profile .titleHolder .pageTitle {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--dusk-blue);
}
.user-profile .staffDetails {
  padding-top: 50px;
  padding-bottom: 54px;
  display: flex;
  align-items: center;
}
.user-profile .staffDetails .left,
.user-profile .staffDetails .right {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
}
.user-profile .staffDetails .separator {
  width: 5.8%;
}
.user-profile .staffDetails .left {
  align-items: flex-end;
}
.user-profile .staffDetails .left .profile {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 96px;
}
.user-profile .staffDetails .left .profile .avatar {
  width: 118.8px;
  height: 118.8px;
  border-radius: 50%;
  margin-right: 53.2px;
  background-position: center;
  background-size: cover;
}
.user-profile .staffDetails .left .profile .info {
  display: flex;
  flex-direction: column;
}
.user-profile .staffDetails .left .profile .info .username {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: var(--dusk-blue);
  margin-bottom: 4px;
}
.user-profile .staffDetails .left .profile .info .idText {
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.31px;
  color: var(--cloudy-blue);
  text-transform: uppercase;
  margin-bottom: 20px;
}
.user-profile .staffDetails .left .profile .info .idText .id {
  color: var(--dark-grey-blue);
}
.user-profile .staffDetails .left .profile .info .uploadPhoto {
  width: 207px;
}
.user-profile .staffDetails .left .inputContainer {
  margin-bottom: 31.9px;
}
.user-profile .staffDetails .right .inputContainer {
  margin-bottom: 31.9px;
}
.user-profile .staffDetails .right .permissionsSection {
  margin-bottom: 114px;
}
.user-profile .staffDetails .right .permissionsSection .inputContainer:nth-of-type(1) {
  margin-bottom: 5px;
}
.user-profile .staffDetails .right .permissionsSection .checkboxContainer {
  width: 380px;
  margin-bottom: 11px;
}
.user-profile .staffDetails .right .buttons {
  display: flex;
  justify-content: space-between;
  width: 380px;
}

.landing {
  width: 100%;
  height: calc(100vh - 80px);
}
.landing .left,
.landing .right {
  width: 50%;
  height: 100%;
}
.landing .left {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: var(--white);
}
.landing .left .title {
  display: block;
  font-size: 50px;
  font-weight: 300;
  line-height: 0.64;
  color: var(--dusk-blue);
  margin-bottom: 15px;
}
.landing .left .subtitle {
  display: block;
  font-size: 15px;
  line-height: 1.47;
  color: var(--bluey-grey);
  margin-bottom: 50px;
}
.landing .left .email {
  margin-bottom: 22px;
}
.landing .left .email-confirm {
  margin-bottom: 50px;
}
.landing .left .button {
  width: 100%;
}
.landing .right {
  background-color: var(--pale-grey);
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.landing .right .card {
  box-sizing: border-box;
  width: 43.2%;
  height: 80vh;
  max-width: 330px;
}
.landing .right .card span {
  display: block;
}
.landing .right .card .list {
  width: 100%;
  position: relative;
  padding-top: 56px;
}
.landing .right .card .list .title {
  border-bottom: 1px var(--pale-grey-two) solid;
  padding: 15px 26px 9px;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: var(--dusk-blue);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background-color: var(--white);
}
.landing .right .card .list .children {
  max-height: calc(80vh - 56px);
  overflow-x: hidden;
}
.landing .right .card .list .children .dailyReminder,
.landing .right .card .list .children .recentNotification,
.landing .right .card .list .children .recentlySearched {
  display: flex;
  flex-direction: column;
  padding: 25px 26px 14px;
  border-bottom: 1px var(--pale-grey-two) solid;
}
.landing .right .card .list .children .dailyReminder .name,
.landing .right .card .list .children .recentNotification .name,
.landing .right .card .list .children .recentlySearched .name {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
  text-transform: uppercase;
}
.landing .right .card .list .children .dailyReminder .email,
.landing .right .card .list .children .recentNotification .email,
.landing .right .card .list .children .recentlySearched .email {
  font-size: 12px;
  line-height: 1.83;
  color: var(--dusk-blue);
}
.landing .right .card .list .children .dailyReminder .advisors,
.landing .right .card .list .children .recentNotification .advisors,
.landing .right .card .list .children .recentlySearched .advisors {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
}
.landing .right .card .list .children .dailyReminder .content,
.landing .right .card .list .children .recentNotification .content,
.landing .right .card .list .children .recentlySearched .content {
  font-size: 15px;
  line-height: 1.47;
  color: var(--dark-grey-blue);
}
.landing .right .card .list .children .dailyReminder .row,
.landing .right .card .list .children .recentNotification .row,
.landing .right .card .list .children .recentlySearched .row {
  justify-content: space-between;
}
.landing .right .card .list .children .dailyReminder .row .date,
.landing .right .card .list .children .recentNotification .row .date,
.landing .right .card .list .children .recentlySearched .row .date {
  font-size: 12px;
  line-height: 1.83;
  color: var(--cloudy-blue-two);
}
.landing .right .card .list .children .dailyReminder .row .open,
.landing .right .card .list .children .recentNotification .row .open,
.landing .right .card .list .children .recentlySearched .row .open {
  font-size: 14px;
  font-weight: 500;
  line-height: 1.5;
  color: var(--clear-blue);
}
.landing .right .card .list .children .dailyReminder .row .open:hover,
.landing .right .card .list .children .recentNotification .row .open:hover,
.landing .right .card .list .children .recentlySearched .row .open:hover {
  cursor: pointer;
}
.landing .right .card .list .children .dailyReminder .row .tickbox,
.landing .right .card .list .children .recentNotification .row .tickbox,
.landing .right .card .list .children .recentlySearched .row .tickbox {
  width: 30.7px;
  height: 30.7px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.landing .right .card .list .children .dailyReminder .row .tickbox:hover,
.landing .right .card .list .children .recentNotification .row .tickbox:hover,
.landing .right .card .list .children .recentlySearched .row .tickbox:hover {
  cursor: pointer;
}
.landing .right .card .list .children .dailyReminder .row .tickbox.active,
.landing .right .card .list .children .recentNotification .row .tickbox.active,
.landing .right .card .list .children .recentlySearched .row .tickbox.active {
  background-color: var(--medium-green);
}
.landing .right .card .list .children .dailyReminder .row .tickbox.inactive,
.landing .right .card .list .children .recentNotification .row .tickbox.inactive,
.landing .right .card .list .children .recentlySearched .row .tickbox.inactive {
  background-color: var(--cloudy-blue);
}
.landing .right .card .list .children .dailyReminder .row .tickbox input,
.landing .right .card .list .children .recentNotification .row .tickbox input,
.landing .right .card .list .children .recentlySearched .row .tickbox input {
  display: none;
}
.landing .right .card .list .children .dailyReminder .email {
  margin-bottom: 5px;
}
.landing .right .card .list .children .dailyReminder .content {
  margin-bottom: 20px;
}
.landing .right .card .list .children .recentNotification .advisors {
  margin-bottom: 11px;
}
.landing .right .card .list .children .recentNotification .row {
  align-items: flex-end;
  margin-top: -9px;
}
.landing .right .card .list .children .recentlySearched {
  flex-direction: row;
  padding: 0;
}
.landing .right .card .list .children .recentlySearched .info,
.landing .right .card .list .children .recentlySearched .remove {
  display: flex;
}
.landing .right .card .list .children .recentlySearched .info:hover,
.landing .right .card .list .children .recentlySearched .remove:hover {
  cursor: pointer;
}
.landing .right .card .list .children .recentlySearched .info {
  padding: 25px 0 25px 25px;
  flex-direction: column;
  flex: 5;
}
.landing .right .card .list .children .recentlySearched .info:hover {
  background-color: var(--clear-blue-007);
}
.landing .right .card .list .children .recentlySearched .remove {
  flex: 1;
  justify-content: center;
  align-items: center;
}
.landing .right .card .list .children .recentlySearched .remove:hover {
  background-color: rgba(255, 0, 0, 0.1);
}

.clientInfo {
  box-sizing: border-box;
  width: 100%;
  padding: 0 7% 47px;
  min-height: calc(100vh - 80px);
}
.clientInfo .loading {
  margin-left: auto;
  margin-right: auto;
  display: block;
  height: 40px;
  width: auto;
}
.clientInfo > .row {
  width: 100%;
}
.clientInfo > .row:first-child {
  justify-content: flex-end;
  margin: 28px 0 33px;
}
.clientInfo > .row:first-child .button:nth-child(1) {
  margin-right: 10px;
}
.clientInfo > .row:nth-child(2) {
  justify-content: space-between;
  align-items: flex-start;
}
.clientInfo > .row:nth-child(2) > .card {
  width: 31%;
  padding: 20px 40px 50px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.clientInfo > .row:nth-child(2) > .card:first-child, .clientInfo > .row:nth-child(2) > .card:last-child {
  padding-bottom: 20px;
}
.clientInfo > .row:nth-child(2) > .card .button.first {
  margin-bottom: 10px;
}
.clientInfo > .row:nth-child(2) > .card .title {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: var(--dusk-blue);
  margin-bottom: 20px;
}
.clientInfo > .row:nth-child(2) > .card .title small {
  display: block;
  font-size: 15px;
  line-height: 1.47;
  color: var(--dark-grey-blue);
}
.clientInfo > .row:nth-child(2) > .card .input {
  margin-bottom: 20px;
}
.clientInfo > .row:nth-child(2) > .card .button {
  margin-bottom: 30px;
}
.clientInfo > .row:nth-child(2) > .card .input,
.clientInfo > .row:nth-child(2) > .card > .button {
  width: 100%;
}
.clientInfo > .row:nth-child(2) > .card .checklist {
  display: flex;
  flex-direction: column;
}
.clientInfo > .row:nth-child(2) > .card .applicants,
.clientInfo > .row:nth-child(2) > .card .advisor_office {
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
.clientInfo > .row:nth-child(2) > .card .applicants .title,
.clientInfo > .row:nth-child(2) > .card .applicants .button,
.clientInfo > .row:nth-child(2) > .card .advisor_office .title,
.clientInfo > .row:nth-child(2) > .card .advisor_office .button {
  margin: 0;
}
.clientInfo > .row:nth-child(2) > .card .applicants .client_id,
.clientInfo > .row:nth-child(2) > .card .applicants .office_details,
.clientInfo > .row:nth-child(2) > .card .advisor_office .client_id,
.clientInfo > .row:nth-child(2) > .card .advisor_office .office_details {
  align-items: center;
}
.clientInfo > .row:nth-child(2) > .card .applicants .client_id label,
.clientInfo > .row:nth-child(2) > .card .applicants .office_details label,
.clientInfo > .row:nth-child(2) > .card .advisor_office .client_id label,
.clientInfo > .row:nth-child(2) > .card .advisor_office .office_details label {
  margin: 0;
}
.clientInfo > .row:nth-child(2) > .card .dynamic_value {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--dark-grey-blue);
  text-transform: uppercase;
}
.clientInfo > .row:nth-child(2) > .card .applicants_buttons {
  justify-content: space-between;
}
.clientInfo > .row:nth-child(2) > .card .applicants_buttons .button:first-child {
  width: 26.5%;
}
.clientInfo > .row:nth-child(2) > .card .applicants_buttons .button:last-child {
  width: 52.3%;
}

.application-status {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.application-status > .card {
  width: 90%;
  margin-bottom: 20px;
  box-sizing: border-box;
}
.application-status > .card:nth-child(2) {
  padding-bottom: 61px;
  padding-right: 3vw;
  padding-left: 3vw;
}
.application-status > .card:nth-child(2) .row {
  flex-wrap: wrap;
  justify-content: space-around;
}
.application-status > .card:nth-child(2) .row .col {
  display: flex;
  flex-direction: column;
  max-width: 300px;
  margin-top: 60px;
}
.application-status > .card:nth-child(2) .row .col:first-child {
  margin-right: 5.9%;
}
.application-status > .card:nth-child(2) .row .col:nth-child(2) {
  margin-right: 9.9%;
}
.application-status > .card:nth-child(2) .row .col:nth-child(3) {
  margin-right: 7.5%;
}
.application-status > .card:nth-child(2) .row .col .big-title {
  font-size: 40px;
  font-weight: 300;
  line-height: 0.8;
  color: var(--dusk-blue);
  margin-bottom: 13px;
}
.application-status > .card:nth-child(2) .row .col .big-subtitle {
  font-size: 15px;
  line-height: 1.47;
  color: var(--bluey-grey);
  margin-bottom: 50px;
}
.application-status > .card:nth-child(2) .row .col .input {
  margin-bottom: 20px;
  width: 320px;
}
.application-status > .card:nth-child(2) .row .col .col-title {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: var(--dusk-blue);
  margin-bottom: 18px;
}
.application-status > .card:nth-child(2) .row .col .field-title {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
  text-transform: uppercase;
}
.application-status > .card:nth-child(2) .row .col .field-content {
  font-size: 15px;
  line-height: 1.47;
  color: var(--dark-grey-blue);
  margin-bottom: 6px;
}
.application-status > .card:nth-child(2) .row .col .button-edit,
.application-status > .card:nth-child(2) .row .col .button-fci {
  margin-top: auto;
}
.application-status > .card:nth-child(3) {
  padding: 60px 5.1% 28px;
}
.application-status > .card:nth-child(3) > .row:first-child {
  align-items: center;
  margin-bottom: 48px;
}
.application-status > .card:nth-child(3) > .row:first-child .status-title {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--dusk-blue);
  margin-right: auto;
}
.application-status > .card:nth-child(3) > .row:first-child .dots {
  border-top: dotted 2px var(--clear-blue-015);
  width: 9.9%;
  margin: 0 10px;
}
.application-status > .card:nth-child(3) > .row:first-child .dots.active {
  border-color: var(--dusk-blue);
}
.application-status > .card:nth-child(3) > .row:first-child > .circle {
  border-radius: 24px;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--pale-grey);
}
.application-status > .card:nth-child(3) > .row:first-child > .circle span {
  font-size: 18px;
  font-weight: bold;
  color: var(--cloudy-blue);
}
.application-status > .card:nth-child(3) > .row:first-child > .circle img {
  display: none;
  width: 15px;
}
.application-status > .card:nth-child(3) > .row:first-child > .circle.active {
  background-color: var(--medium-green);
}
.application-status > .card:nth-child(3) > .row:first-child > .circle.active span {
  display: none;
}
.application-status > .card:nth-child(3) > .row:first-child > .circle.active img {
  display: block;
}
.application-status > .card:nth-child(3) > .row:nth-child(2) {
  justify-content: space-between;
}
.application-status > .card:nth-child(3) > .row:nth-child(2) .document {
  display: flex;
  align-items: center;
  margin-bottom: 22px;
}
.application-status > .card:nth-child(3) > .row:nth-child(2) .document .circle {
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border: solid 1px var(--bluegrey);
  background-color: var(--white);
  opacity: 0.5;
  box-sizing: border-box;
  margin-right: 10px;
}
.application-status > .card:nth-child(3) > .row:nth-child(2) .document .circle.active {
  opacity: 1;
  border: solid 4px var(--clear-blue);
}
.application-status > .card:nth-child(3) > .row:nth-child(2) .document .document-title {
  font-size: 15px;
  color: var(--dark-grey-blue);
}

.login {
  width: 100%;
  height: 100vh;
  display: flex;
}
.login .misc {
  width: 58.3%;
  height: 100%;
  position: relative;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 143px;
  box-sizing: border-box;
}
.login .misc .logo {
  width: 58.1%;
  max-width: 519px;
}
.login .form {
  width: 41.7%;
  height: 100%;
  background-color: var(--white);
  display: flex;
  justify-content: center;
  align-items: center;
}
.login .form .form-holder {
  max-width: 315px;
}
.login .form .form-holder .title {
  display: inline-block;
  font-size: 50px;
  font-weight: 300;
  line-height: 0.64;
  color: var(--dusk-blue);
  margin-bottom: 15px;
}
.login .form .form-holder .subtitle {
  display: inline-block;
  font-size: 15px;
  line-height: 1.47;
  color: var(--bluey-grey);
  margin-bottom: 50px;
}
.login .form .form-holder .inputContainer:first-of-type {
  margin-bottom: 22px;
}
.login .form .form-holder .inputContainer .input {
  width: 315px;
}
.login .form .form-holder .warning {
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0 31px;
}
.login .form .form-holder .warning span {
  font-size: 15px;
  color: var(--orangey-red);
}
.login .form .form-holder .separator {
  height: 60px;
  width: 1px;
}

.reminders {
  width: 90%;
  justify-content: center;
}
.reminders .reminders-list {
  padding: 30px 32px 37px 26px;
  display: flex;
  flex-direction: column;
  width: 58.3vw;
  max-width: 840px;
  margin-right: 45px;
}
.reminders .reminders-list .title {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: var(--dusk-blue);
  margin-bottom: 40px;
}
.reminders .reminders-list .labels {
  display: flex;
  border-bottom: solid 2px var(--pale-grey);
  margin-bottom: 12px;
}
.reminders .reminders-list .labels > * {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
  text-transform: uppercase;
  margin-bottom: 7px;
}
.reminders .reminders-list .labels > *:nth-child(1) {
  width: 94px;
}
.reminders .reminders-list .labels > *:nth-child(2) {
  width: 118px;
}
.reminders .reminders-list .labels > *:nth-child(3) {
  width: 142px;
}
.reminders .reminders-list .labels > *:nth-child(4) {
  width: 107px;
}
.reminders .reminders-list .labels > *:nth-child(5) {
  width: 130px;
}
.reminders .reminders-list .labels > *:last-child {
  margin-right: 142px;
}
.reminders .reminders-list .list .reminder {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
}
.reminders .reminders-list .list .reminder .action {
  width: 92px;
  font-size: 12px;
  line-height: 1.83;
  color: var(--medium-green);
}
.reminders .reminders-list .list .reminder .subject {
  width: 120px;
  font-size: 12px;
  line-height: 1.83;
  color: var(--dusk-blue);
}
.reminders .reminders-list .list .reminder .dateCreated {
  width: 142px;
  font-size: 12px;
  line-height: 1.83;
  color: var(--dark-grey-blue);
}
.reminders .reminders-list .list .reminder .dateDue {
  width: 107px;
  font-size: 12px;
  line-height: 1.83;
  color: var(--dark-grey-blue);
}
.reminders .reminders-list .list .reminder .actionDate {
  width: 130px;
  font-size: 11px;
  line-height: 2;
  color: var(--dark-grey-blue);
}
.reminders .reminders-list .list .reminder .actions .button {
  width: 80px;
  min-height: 28px;
  height: 28px;
  box-sizing: border-box;
  padding: 0;
  font-size: 10px;
  font-weight: 500;
  line-height: 2.1;
  text-align: center;
  color: var(--white);
  margin-right: 5px;
}
.reminders .reminders-list .list .reminder .actions .button:last-of-type {
  margin-right: 15px;
}
.reminders .reminders-list .list .reminder .actions img:hover {
  cursor: pointer;
}
.reminders .form {
  padding: 30px 26px;
  display: flex;
  flex-direction: column;
  height: fit-content;
}
.reminders .form .title {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: var(--dusk-blue);
  margin-bottom: 25px;
}
.reminders .form .detail {
  margin-bottom: 1px;
  align-items: center;
}
.reminders .form .detail:nth-of-type(2) {
  margin-bottom: 20px;
}
.reminders .form .detail .label {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
  text-transform: uppercase;
  margin-right: 5px;
}
.reminders .form .detail .value {
  font-size: 12px;
  line-height: 1.83;
  color: var(--medium-green);
}
.reminders .form textarea {
  width: 25vw;
  max-width: 360px;
  resize: none;
  border-radius: 3px;
  background-color: var(--pale-grey);
  border-color: transparent;
  padding: 15px;
  margin-bottom: 25px;
  font-size: 15px;
  line-height: 1.47;
  height: 200px;
}
.reminders .form textarea:focus {
  outline: none;
}
.reminders .form .date {
  margin-bottom: 35px;
}

.documents {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;
}
.documents .client-info {
  padding: 30px 60px 40px 71px;
  justify-content: space-between;
  width: 90%;
  max-width: 1295px;
  margin-bottom: 30px;
  box-sizing: border-box;
}
.documents .client-info .col {
  display: flex;
  flex-direction: column;
}
.documents .client-info .col .title {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: var(--dusk-blue);
  margin-bottom: 18px;
}
.documents .client-info .col .inputLabel {
  margin-bottom: 0;
}
.documents .client-info .col .value {
  margin-bottom: 6px;
  font-size: 15px;
  line-height: 1.47;
  color: var(--dark-grey-blue);
}
.documents .client-info .col .button {
  margin-top: 53px;
}
.documents .page-title {
  width: 90%;
  max-width: 1295px;
  font-size: 28px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--dusk-blue);
  margin-bottom: 30px;
}
.documents .documents-column {
  width: 90%;
  max-width: 1295px;
  justify-content: space-between;
}
.documents .documents-column .section {
  width: 24.5%;
  max-width: 315px;
  padding: 21px 27px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}
.documents .documents-column .section .title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--dusk-blue);
  margin-bottom: 18px;
}
.documents .documents-column .section .labels {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
  text-transform: uppercase;
  padding-bottom: 7px;
  border-bottom: solid 3px var(--pale-grey);
  margin-bottom: 12px;
  justify-content: space-between;
}
.documents .documents-column .section .labels .date {
  margin-right: 30px;
}
.documents .documents-column .section .document {
  justify-content: space-between;
}
.documents .documents-column .section .document:last-child {
  margin-bottom: 21px;
}
.documents .documents-column .section .document .name {
  font-size: 12px;
  line-height: 1.83;
  color: var(--medium-green);
}
.documents .documents-column .section .document .date {
  font-size: 12px;
  line-height: 1.83;
  color: var(--dark-grey-blue);
}
.documents .documents-column .section .button {
  margin-top: auto;
}

.notifications .page-title {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--dusk-blue);
  margin-bottom: 30px;
  display: inline-block;
}
.notifications .cards {
  justify-content: space-between;
  width: 90vw;
  max-width: 1290px;
}
.notifications .cards .card {
  display: flex;
  flex-direction: column;
  padding: 30px 26px;
  width: 28%;
  max-width: 410px;
}
.notifications .cards .card:first-child > .inputContainer {
  width: 100%;
}
.notifications .cards .card:first-child > .inputContainer > .input {
  width: 100%;
}
.notifications .cards .card:first-child > .inputContainer img {
  top: 40px;
}
.notifications .cards .card > .title {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--dusk-blue);
  margin-bottom: 25px;
}
.notifications .cards .card .labels {
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
  text-transform: uppercase;
  justify-content: flex-start;
  padding-bottom: 7px;
  border-bottom: solid 1.5px var(--clear-blue-008);
}
.notifications .cards .card .labels .action {
  width: 126px;
}
.notifications .cards .card .labels .subject {
  width: 168px;
}
.notifications .cards .card .labels .date {
  width: 66px;
}
.notifications .cards .card .item {
  margin-top: 12px;
}
.notifications .cards .card .item:hover {
  cursor: pointer;
}
.notifications .cards .card .item:nth-last-of-type(2) {
  margin-bottom: 30px;
}
.notifications .cards .card .item .action {
  font-size: 12px;
  line-height: 1.83;
  color: var(--medium-green);
  width: 126px;
}
.notifications .cards .card .item .subject {
  font-size: 12px;
  line-height: 1.83;
  color: var(--dusk-blue);
  width: 168px;
}
.notifications .cards .card .item .date {
  font-size: 12px;
  line-height: 1.83;
  color: var(--dark-grey-blue);
  width: 66px;
}
.notifications .cards .card:first-of-type .buttons {
  margin-top: auto;
  width: 100%;
  justify-content: space-between;
}
.notifications .cards .card:first-of-type .buttons .button:first-of-type {
  width: 46.8%;
  max-width: 195px;
}
.notifications .cards .card:first-of-type .buttons .button:last-of-type {
  width: 37.2%;
  max-width: 155px;
}
.notifications .cards .card:last-of-type .buttons {
  justify-content: space-between;
  margin-bottom: 12px;
}
.notifications .cards .card:last-of-type .buttons .button:first-child {
  width: 43%;
  max-width: 179px;
}
.notifications .cards .card:last-of-type .buttons .button:last-child {
  width: 40.6%;
  max-width: 169px;
}
.notifications .cards .card .template {
  padding: 0 16.5px 0 10px;
  height: 45px;
  align-items: center;
  justify-content: space-between;
  border-radius: 1px;
  border: solid 1px var(--clear-blue-008);
  margin-bottom: 15px;
}
.notifications .cards .card .template:last-of-type {
  margin-bottom: 0;
}
.notifications .cards .card .template .title {
  font-size: 15px;
  font-weight: 500;
  color: var(--dark-grey-blue);
}
.notifications .cards .card .template .open {
  font-size: 15px;
  font-weight: 500;
  line-height: 1.4;
  color: var(--clear-blue);
}
.notifications .cards .card .template .open:hover {
  cursor: pointer;
}
.notifications .cards .card input {
  height: 50px;
  padding: 0 15px;
  font-size: 15px;
  line-height: 1.47;
  border-radius: 5px;
  background-color: var(--pale-grey);
  border: none;
  margin-bottom: 21px;
}
.notifications .cards .card input:active, .notifications .cards .card input:focus {
  outline: none;
}
.notifications .cards .card textarea {
  padding: 15px;
  height: 340px;
  font-size: 15px;
  line-height: 1.47;
  border-radius: 1px;
  background-color: var(--pale-grey);
  border: none;
  margin-bottom: 20px;
  resize: none;
}
.notifications .cards .card textarea:active, .notifications .cards .card textarea:focus {
  outline: none;
}
.add-new-client {
  display: flex;
  flex-direction: column;
  padding-bottom: 75px;
}
.add-new-client .page-title {
  font-size: 28px;
  font-weight: 300;
  line-height: 1.14;
  color: var(--dusk-blue);
  margin: 40px 0 30px;
}
.add-new-client .row {
  align-items: flex-start;
}
.add-new-client .row .card {
  padding: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.add-new-client .row .card:first-child {
  margin-right: 30px;
}
.add-new-client .row .card .section-title {
  font-size: 22px;
  font-weight: 300;
  line-height: 1.45;
  color: var(--dusk-blue);
  margin-bottom: 22px;
}
.add-new-client .row .card .section-title:not(:first-child) {
  margin-top: 21px;
}
.add-new-client .row .card .input {
  margin-bottom: 12px;
}
.add-new-client .row .card .button {
  margin-top: 20px;
}