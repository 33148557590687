.user-profile {
  @extend %noselect;
  //height: calc(100vh - 80px);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 10vh;

  .titleHolder {
    padding-top: 3vh;
    padding-bottom: 3vh;
    width: 75%;

    .pageTitle {
      font-size: 28px;
      font-weight: 300;
      line-height: 1.14;
      color: var(--dusk-blue);
    }
  }

  .staffDetails {
    padding-top: 50px;
    padding-bottom: 54px;
    display: flex;
    align-items: center;

    .left,
    .right {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
    }

    .separator {
      width: 5.8%;
    }

    .left {
      align-items: flex-end;

      .profile {
        display: flex;
        justify-content: flex-end;
        margin-bottom: 96px;
        //margin-top: -120px;

        .avatar {
          width: 118.8px;
          height: 118.8px;
          border-radius: 50%;
          margin-right: 53.2px;
          background-position: center;
          background-size: cover;
        }

        .info {
          display: flex;
          flex-direction: column;

          .username {
            font-size: 22px;
            font-weight: 300;
            line-height: 1.45;
            color: var(--dusk-blue);
            margin-bottom: 4px;
          }

          .idText {
            font-size: 14px;
            font-weight: 500;
            letter-spacing: 1.31px;
            color: var(--cloudy-blue);
            text-transform: uppercase;
            margin-bottom: 20px;

            .id {
              color: var(--dark-grey-blue);
            }
          }

          .uploadPhoto {
            width: 207px;
          }
        }
      }

      .inputContainer {
        margin-bottom: 31.9px;
      }
    }

    .right {
      .inputContainer {
        margin-bottom: 31.9px;
      }

      .permissionsSection {
        margin-bottom: 114px;

        .inputContainer {
          &:nth-of-type(1) {
            margin-bottom: 5px;
          }
        }
        .checkboxContainer {
          width: 380px;
          margin-bottom: 11px;
        }
      }

      .buttons {
        display: flex;
        justify-content: space-between;
        width: 380px;
      }
    }
  }
}