.reminders {
  width: 90%;
  justify-content: center;

  .reminders-list {
    padding: 30px 32px 37px 26px;
    display: flex;
    flex-direction: column;
    width: 58.3vw;
    max-width: 840px;
    margin-right: 45px;

    .title {
      font-size: 22px;
      font-weight: 300;
      line-height: 1.45;
      color: var(--dusk-blue);
      margin-bottom: 40px;
    }

    .labels {
      display: flex;
      border-bottom: solid 2px var(--pale-grey);
      margin-bottom: 12px;

      > * {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.13px;
        color: var(--cloudy-blue);
        text-transform: uppercase;
        margin-bottom: 7px;

        &:nth-child(1) {
          width: 94px;
        }

        &:nth-child(2) {
          width: 118px;
        }

        &:nth-child(3) {
          width: 142px;
        }

        &:nth-child(4) {
          width: 107px;
        }

        &:nth-child(5) {
          width: 130px;
        }

        &:last-child {
          margin-right: 142px;
        }
      }
    }

    .list {
      .reminder {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;

        .action {
          width: 92px;
          font-size: 12px;
          line-height: 1.83;
          color: var(--medium-green);
        }

        .subject {
          width: 120px;
          font-size: 12px;
          line-height: 1.83;
          color: var(--dusk-blue);
        }

        .dateCreated {
          width: 142px;
          font-size: 12px;
          line-height: 1.83;
          color: var(--dark-grey-blue);
        }

        .dateDue {
          width: 107px;
          font-size: 12px;
          line-height: 1.83;
          color: var(--dark-grey-blue);
        }

        .actionDate {
          width: 130px;
          font-size: 11px;
          line-height: 2;
          color: var(--dark-grey-blue);
        }

        .actions {
          .button {
            width: 80px;
            min-height: 28px;
            height: 28px;
            box-sizing: border-box;
            padding: 0;
            font-size: 10px;
            font-weight: 500;
            line-height: 2.1;
            text-align: center;
            color: var(--white);
            margin-right: 5px;

            &:last-of-type {
              margin-right: 15px;
            }
          }

          img {
            &:hover {
              cursor: pointer;
            }
          }
        }
      }
    }
  }

  .form {
    padding: 30px 26px;
    display: flex;
    flex-direction: column;
    height: fit-content;

    .title {
      font-size: 22px;
      font-weight: 300;
      line-height: 1.45;
      color: var(--dusk-blue);
      margin-bottom: 25px;
    }

    .detail {
      margin-bottom: 1px;
      align-items: center;

      &:nth-of-type(2) {
        margin-bottom: 20px;
      }

      .label {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.13px;
        color: var(--cloudy-blue);
        text-transform: uppercase;
        margin-right: 5px;
      }

      .value {
        font-size: 12px;
        line-height: 1.83;
        color: var(--medium-green);
      }
    }

    textarea {
      width: 25vw;
      max-width: 360px;
      resize: none;
      border-radius: 3px;
      background-color: var(--pale-grey);
      border-color: transparent;
      padding: 15px;
      margin-bottom: 25px;
      font-size: 15px;
      line-height: 1.47;
      height: 200px;

      &:focus {
        outline: none;
      }
    }

    .date {
      margin-bottom: 35px;
    }
  }
}