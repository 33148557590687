.application-status {
  @extend %noselect;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  > .card {
    width: 90%;
    margin-bottom: 20px;
    box-sizing: border-box;

    &:nth-child(2) {
      padding-bottom: 61px;
      padding-right: 3vw;
      padding-left: 3vw;

      .row {
        flex-wrap: wrap;
        justify-content: space-around;

        .col {
          display: flex;
          flex-direction: column;
          max-width: 300px;
          margin-top: 60px;

          &:first-child {
            margin-right: 5.9%;
          }

          &:nth-child(2) {
            margin-right: 9.9%;
          }

          &:nth-child(3) {
            margin-right: 7.5%;
          }

          .big-title {
            font-size: 40px;
            font-weight: 300;
            line-height: 0.8;
            color: var(--dusk-blue);

            margin-bottom: 13px;
          }

          .big-subtitle {
            font-size: 15px;
            line-height: 1.47;
            color: var(--bluey-grey);

            margin-bottom: 50px;
          }

          .input {
            margin-bottom: 20px;
            width: 320px;
          }

          .col-title {
            font-size: 22px;
            font-weight: 300;
            line-height: 1.45;
            color: var(--dusk-blue);
            margin-bottom: 18px;
          }

          .field-title {
            font-size: 12px;
            font-weight: 500;
            letter-spacing: 1.13px;
            color: var(--cloudy-blue);
            text-transform: uppercase;
          }

          .field-content {
            font-size: 15px;
            line-height: 1.47;
            color: var(--dark-grey-blue);
            margin-bottom: 6px;
          }

          .button-edit,
          .button-fci {
            margin-top: auto;
          }
        }
      }
    }

    &:nth-child(3) {
      padding: 60px 5.1% 28px;

      >.row:first-child {
        align-items: center;
        margin-bottom: 48px;

        .status-title {
          font-size: 28px;
          font-weight: 300;
          line-height: 1.14;
          color: var(--dusk-blue);
          margin-right: auto;
        }

        .dots {
          border-top: dotted 2px var(--clear-blue-015);
          width: 9.9%;
          margin: 0 10px;

          &.active {
            border-color: var(--dusk-blue);
          }
        }

        >.circle {
          border-radius: 24px;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: var(--pale-grey);

          span {
            font-size: 18px;
            font-weight: bold;
            color: var(--cloudy-blue);
          }

          img {
            display: none;
            width: 15px;
          }

          &.active {
            background-color: var(--medium-green);

            span {
              display: none;
            }

            img {
              display: block;
            }
          }
        }
      }

      >.row:nth-child(2) {
        justify-content: space-between;

        .document {
          display: flex;
          align-items: center;
          margin-bottom: 22px;

          .circle {
            width: 16px;
            height: 16px;
            border-radius: 8px;
            border: solid 1px var(--bluegrey);
            background-color: var(--white);
            opacity: 0.5;
            box-sizing: border-box;
            margin-right: 10px;

            &.active {
              opacity: 1;
              border: solid 4px var(--clear-blue);
            }
          }

          .document-title {
            font-size: 15px;
            color: var(--dark-grey-blue);
          }
        }
      }
    }
  }
}


