.add-new-client {
  display: flex;
  flex-direction: column;
  padding-bottom: 75px;

  .page-title {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;
    color: var(--dusk-blue);
    margin: 40px 0 30px;
  }

  .row {
    align-items: flex-start;

    .card {
      padding: 30px 40px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      &:first-child {
        margin-right: 30px;
      }

      .section-title {
        font-size: 22px;
        font-weight: 300;
        line-height: 1.45;
        color: var(--dusk-blue);
        margin-bottom: 22px;

        &:not(:first-child) {
          margin-top: 21px;
        }
      }

      .input {
        margin-bottom: 12px;
      }

      .button {
        margin-top: 20px;
      }
    }
  }
}