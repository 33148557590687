.clientInfo {
  @extend %noselect;
  box-sizing: border-box;
  width: 100%;
  padding: 0 7% 47px;
  min-height: calc(100vh - 80px);

  .loading {
    margin-left: auto;
    margin-right: auto;
    display: block;
    height: 40px;
    width: auto;
  }

  > .row {
    width: 100%;

    &:first-child {
      justify-content: flex-end;
      margin: 28px 0 33px;

      .button:nth-child(1) {
        margin-right: 10px;
      }
    }

    &:nth-child(2) {
      justify-content: space-between;
      align-items: flex-start;

      > .card {
        width: 31%;
        padding: 20px 40px 50px;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;

        &:first-child,
        &:last-child {
          padding-bottom: 20px;
        }

        .button.first {
          margin-bottom: 10px;
        }

        .title {
          font-size: 22px;
          font-weight: 300;
          line-height: 1.45;
          color: var(--dusk-blue);
          margin-bottom: 20px;

          small {
            display: block;
            font-size: 15px;
            line-height: 1.47;
            color: var(--dark-grey-blue);
          }
        }

        .input {
          margin-bottom: 20px;
        }

        .button {
          margin-bottom: 30px;
        }

        .input,
        > .button {
          width: 100%;
        }

        .checklist {
          display: flex;
          flex-direction: column;
        }

        .applicants,
        .advisor_office {
          justify-content: space-between;
          align-items: center;
          margin-bottom: 20px;

          .title,
          .button {
            margin: 0;
          }

          .client_id,
          .office_details {
            align-items: center;

            label {
              margin: 0;
            }
          }
        }

        .dynamic_value {
          font-size: 12px;
          font-weight: 500;
          letter-spacing: 1.13px;
          color: var(--dark-grey-blue);
          text-transform: uppercase;
        }

        .applicants_buttons {
          justify-content: space-between;

          .button {
            &:first-child {
              width: 26.5%;
            }

            &:last-child {
              width: 52.3%;
            }
          }
        }
      }
    }

  }
}