.button {
  @extend %noselect;
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  font-weight: 500;
  font-size: 15px;
  line-height: 1.4;
  padding-left: 24px;
  padding-right: 24px;
  white-space: nowrap;
  min-height: 40px;
  position: relative;

  &:hover {
    cursor: pointer;
  }
}

header {
  @extend %noselect;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  background-color: var(--dusk-blue);
  height: 10vh;
  max-height: 80px;
  justify-content: center;
  padding-right: 3.5vw;
  z-index: 9;

  .logo {
    margin-left: 1.9vw;
    margin-right: 5.4vw;
    height: 50px;

    &:hover {
      cursor: pointer;
    }
  }

  .link {
    color: white;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    letter-spacing: 0.47px;
    white-space: nowrap;

    &:hover {
      cursor: pointer;
    }

    &:nth-of-type(1) {
      margin-right: 1.8vw;
    }

    &:nth-of-type(2) {
      margin-right: 2.6vw;
    }

    &:nth-of-type(3) {
      margin-right: 2.5vw;
    }

    &:nth-of-type(4) {
      margin-right: 0.8vw;
    }
  }

  .bellContainer {
    width: 26px;
    height: 18.5px;
    position: relative;
    margin-right: 3.1vw;

    &:hover {
      cursor: pointer;
    }

    .bell {
      height: 2.5vh;
      margin-top: -1.5px;
    }

    .numberHolder {
      position: absolute;
      top: -0.5vh;
      right: -0.2vw;
      width: 100%;
      height: 100%;
      max-width: 15px;
      max-height: 15px;
      border: solid 1px white;
      box-sizing: border-box;
      border-radius: 50%;
      background-color: var(--clear-blue);
      display: flex;
      align-items: center;
      justify-content: center;

      .number {
        font-size: 7px;
        letter-spacing: 1px;
        color: white;
      }
    }
  }

  .searchContainer {
    width: 16vw;
    height: 42px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    background-color: var(--pale-grey-01);
    margin-right: 6.1vw;
    padding-left: 13px;
    padding-right: 13px;

    .searchBar {
      background-color: transparent;
      border: none;
      color: white;
      outline: none;
      width: 11.9vw;
      min-height: 20px;

      &::placeholder {
        color: var(--bluey-grey);
        font-size: 12px;
      }
    }

    .searchIcon {
      margin-left: 17px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .profile {
    display: flex;
    align-items: center;
    margin-right: 1vw;

    &:hover {
      cursor: pointer;
    }

    .profileText {
      font-size: 14px;
      font-weight: 300;
      line-height: 1.5;
      letter-spacing: 0.5px;
      color: white;
      margin-right: 1.4vw;
      white-space: nowrap;
    }

    .avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background-position: center;
      background-size: contain;
    }
  }

  .logout {
  }

  .version_text {
    position: absolute;
    top: 5px;
    right: 5px;
    color: rgba(255, 255, 255, 0.3);
    font-size: 8pt;
  }
}

.card {
  box-shadow: 0 10px 20px 0 var(--clear-blue-007);
  background-color: var(--white);
  border-radius: 1px;
  border: solid 1px var(--clear-blue-008);
}

.inputContainer {
  display: flex;
  flex-direction: column;
  position: relative;

  &.radio,
  &.date {
    > input:hover {
      cursor: pointer;
    }

    > img {
      top: 36px;
    }
  }

  &.date {
    .box {
      width: 430px;
    }

    .arrow:hover {
      cursor: pointer;
    }

    .calendar {
      flex-wrap: wrap;
      justify-content: flex-start !important;
      margin-top: 15px;

      .day {
        display: flex;
        width: 14.285%;
        height: 40px;
        justify-content: center;
        align-items: center;

        &.active {
          background-color: var(--clear-blue);
          color: var(--white);
        }

        &:hover {
          cursor: pointer;
          background-color: var(--pale-lilac);
        }

        &.nohover {
          cursor: default;
          background-color: var(--white);
        }
      }
    }
  }

  .inputLabel {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 1.13px;
    color: var(--cloudy-blue);
    margin-bottom: 7px;
    text-transform: uppercase;
  }

  .input {
    width: 380px;
    height: 38.1px;
    border-radius: 5px;
    border: solid 1px var(--pale-lilac);
    background-color: var(--pale-lilac-02);
    padding-left: 16px;
    padding-right: 22.8px;
    outline-color: var(--cloudy-blue);
    font-size: 15px;
    color: var(--dark-grey-blue);
    box-sizing: border-box;

    &:active, &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--cloudy-blue);
    }

    &.multiSelect {
      cursor: pointer;
    }
  }

  &.correct .input {
    border-color: var(--medium-green);
  }

  > img {
    position: absolute;
    bottom: 14px;
    right: 17px;
    cursor: pointer;
  }
}

.checkboxContainer {
  width: 380px;
  display: flex;
  justify-content: space-between;

  .inputLabel {
    font-size: 15px;
    font-weight: 500;
    line-height: 1.47;
    color: var(--dark-grey-blue);
  }
}

.row {
  display: flex;
  flex-direction: row;
}

.light-box {
  @extend %noselect;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;

  .shadow {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--black-07);
    display: flex;
    justify-content: center;
    align-items: center;

    .box {
      padding: 40px 25px 30px;
      min-width: 430px;
      min-height: 450px;
      box-sizing: border-box;

      &:hover {
        cursor: auto;
      }

      .title {
        font-size: 28px;
        font-weight: 300;
        line-height: 1.14;
        margin-bottom: 25px;
        color: var(--dusk-blue);
        display: block;
        text-transform: capitalize;
      }

      .children {
        @extend %scrollBar;

        height: 300px;
        overflow: auto;
        margin-bottom: 13px;
      }

      .row {
        justify-content: space-between;
      }
    }
  }
}

.checkbox {
  width: 22px;
  height: 22px;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;

  &:hover {
    cursor: pointer;
  }

  &.active {
    background-color: var(--medium-green);
  }

  &.inactive {
    background-color: var(--cloudy-blue);
  }
}

.multipleSelectItem {
  padding: 13px 0;

  &:hover {
    cursor: pointer;
    background-color: var(--pale-lilac);
  }

  .label {
    font-size: 18px;
    color: var(--dark-grey-blue);
  }
}

.multipleRadioItem {
  justify-content: flex-start !important;
  align-items: center;
  padding: 13px 0;

  &:hover {
    cursor: pointer;
    background-color: var(--pale-lilac);
  }

  .radio {
    width: 22px;
    height: 22px;
    border-radius: 50%;
    margin-right: 11px;
    box-sizing: border-box;

    &.active {
      border: solid 4px var(--clear-blue);
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &.inactive {
      opacity: 0.18;
      border: solid 3px var(--bluegrey);
    }
  }

  .label {
    font-size: 18px;
    color: var(--dark-grey-blue);
    text-transform: capitalize;
  }
}

.sidebar-list.shadow {
  @extend %noselect;
  position: fixed;
  left: 0;
  right: 0;
  top: 80px;
  bottom: 0;
  height: 100%;
  background-color: var(--black-07);
  z-index: 3;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;

  .sidebar {
    cursor: default;
    height: 100%;
    background-color: var(--white);
    width: 330px;
    overflow: auto;
    @extend %scrollBar;

    > .row {
      justify-content: space-between;
      align-items: center;
      padding: 40px 24px;

      .title {
        font-size: 28px;
        font-weight: 300;
        line-height: 1.14;
        color: var(--dusk-blue);
      }
    }

    .item {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid var(--clear-blue-008);
      padding: 0 24px;
      margin-bottom: 25px;

      .name {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.13px;
        color: var(--cloudy-blue);
      }

      .email {
        font-size: 12px;
        line-height: 1.83;
        color: var(--dusk-blue);
        margin-bottom: 5px;
      }

      .content {
        font-size: 15px;
        line-height: 1.47;
        color: var(--dark-grey-blue);
        margin-bottom: 20px;
      }

      > .row {
        justify-content: space-between;
        margin-bottom: 14px;

        .date {
          font-size: 12px;
          line-height: 1.83;
          color: var(--cloudy-blue-two);
        }

        .open {
          font-size: 14px;
          font-weight: 500;
          line-height: 1.5;
          color: var(--clear-blue);

          &:hover {
            cursor: pointer;
          }
        }
      }
    }
  }
}

.search-light-box {
  width: 75.7%;

  > .row {
    justify-content: flex-start !important;

    .button:first-child {
      margin-right: 20px;
    }
  }
}

.fast-navigation {
  @extend %noselect;
  min-height: 95px;
  align-items: center;

  .fast-navigation-item {
    align-items: center;
    margin: 0 30px;

    &:hover {
      cursor: pointer;
    }

    &.active {
      opacity: 0.5;
    }

    .icon {
      margin-right: 15px;
    }

    .text {
      margin-right: 10px;
      font-size: 15px;
      font-weight: 500;
      line-height: 1.4;
      color: var(--dusk-blue);
    }

    .arrow {
      margin-top: 2px;
      font-size: 18px;
      font-weight: 500;
      line-height: 1.4;
      color: var(--dusk-blue);
    }
  }
}

.checklist-item {
  width: 100%;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 30px;
  }

  label {
    font-size: 12px;
    color: var(--dark-grey-blue);
  }

  .checkbox {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    opacity: 0.18;
    border-radius: 6px;
    border: solid 2px var(--bluegrey);

    &.active {
      opacity: 1;
      border-color: var(--clear-blue);
      background-color: var(--clear-blue);
    }

    img {
      height: 10px;
      width: 10px;
    }
  }
}

.loader {
  display: block;
  margin: 0 auto;
  width: 3vw;
}

.nothing_here {
  display: block;
  margin: 15px 26px;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 1.13px;
  color: var(--cloudy-blue);
}

.page-under-development {
  font-size: 40px;
  font-weight: 300;
  line-height: 0.8;
  color: var(--dusk-blue);
  margin: 75px auto;
}

.addNewAdvisor {
  @extend %noselect;

  .input {
    width: 100%;
  }
}

.office-input {
  width: 100%;
  margin-bottom: 12px;

  > input.input {
    width: 100%;
  }
}

@import "iOSSwitchStyle";
