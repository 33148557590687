.notifications {
  @extend %noselect;

  .page-title {
    font-size: 28px;
    font-weight: 300;
    line-height: 1.14;
    color: var(--dusk-blue);
    margin-bottom: 30px;
    display: inline-block;
  }

  .cards {
    justify-content: space-between;
    width: 90vw;
    max-width: 1290px;

    .card {
      display: flex;
      flex-direction: column;
      padding: 30px 26px;
      width: 28%;
      max-width: 410px;

      &:first-child {
        > .inputContainer {
          width: 100%;

          > .input {
            width: 100%;
          }

          img {
            top: 40px;
          }
        }
      }

      > .title {
        font-size: 15px;
        font-weight: 500;
        line-height: 1.4;
        color: var(--dusk-blue);
        margin-bottom: 25px;
      }

      .labels {
        font-size: 12px;
        font-weight: 500;
        letter-spacing: 1.13px;
        color: var(--cloudy-blue);
        text-transform: uppercase;
        justify-content: flex-start;
        padding-bottom: 7px;
        border-bottom: solid 1.5px var(--clear-blue-008);

        .action {
          width: 126px
        }

        .subject {
          width: 168px;
        }

        .date {
          width: 66px;
        }
      }

      .item {
        margin-top: 12px;

        &:hover {
          cursor: pointer;
        }

        &:nth-last-of-type(2) {
          margin-bottom: 30px;
        }

        .action {
          font-size: 12px;
          line-height: 1.83;
          color: var(--medium-green);
          width: 126px
        }

        .subject {
          font-size: 12px;
          line-height: 1.83;
          color: var(--dusk-blue);
          width: 168px;
        }

        .date {
          font-size: 12px;
          line-height: 1.83;
          color: var(--dark-grey-blue);
          width: 66px;
        }
      }

      &:first-of-type .buttons {
        margin-top: auto;
        width: 100%;
        justify-content: space-between;

        .button {
          &:first-of-type {
            width: 46.8%;
            max-width: 195px;
          }

          &:last-of-type {
            width: 37.2%;
            max-width: 155px;
          }
        }
      }

      &:last-of-type .buttons {
        justify-content: space-between;
        margin-bottom: 12px;

        .button {
          &:first-child {
            width: 43%;
            max-width: 179px;
          }

          &:last-child {
            width: 40.6%;
            max-width: 169px;
          }
        }
      }

      .template {
        padding: 0 16.5px 0 10px;
        height: 45px;
        align-items: center;
        justify-content: space-between;
        border-radius: 1px;
        border: solid 1px var(--clear-blue-008);
        margin-bottom: 15px;

        &:last-of-type {
          margin-bottom: 0;
        }

        .title {
          font-size: 15px;
          font-weight: 500;
          color: var(--dark-grey-blue);
        }

        .open {
          font-size: 15px;
          font-weight: 500;
          line-height: 1.4;
          color: var(--clear-blue);

          &:hover {
            cursor: pointer;
          }
        }
      }

      input {
        height: 50px;
        padding: 0 15px;
        font-size: 15px;
        line-height: 1.47;
        border-radius: 5px;
        background-color: var(--pale-grey);
        border: none;
        margin-bottom: 21px;

        &:active, &:focus {
          outline: none;
        }

      }

      textarea {
        padding: 15px;
        height: 340px;
        font-size: 15px;
        line-height: 1.47;
        border-radius: 1px;
        background-color: var(--pale-grey);
        border: none;
        margin-bottom: 20px;
        resize: none;

        &:active, &:focus {
          outline: none;
        }
      }
    }
  }

  .actions-list {
    @extend %scrollBar;
  }
}