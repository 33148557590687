.landing {
  width: 100%;
  height: calc(100vh - 80px);

  .left,
  .right {
    width: 50%;
    height: 100%;
  }

  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: var(--white);

    .title {
      display: block;
      font-size: 50px;
      font-weight: 300;
      line-height: 0.64;
      color: var(--dusk-blue);
      margin-bottom: 15px;
    }

    .subtitle {
      display: block;
      font-size: 15px;
      line-height: 1.47;
      color: var(--bluey-grey);
      margin-bottom: 50px;
    }

    .email {
      margin-bottom: 22px;
    }

    .email-confirm {
      margin-bottom: 50px;
    }

    .button {
      width: 100%
    }
  }

  .right {
    background-color: var(--pale-grey);
    display: flex;
    justify-content: space-around;
    align-items: center;
    @extend %noselect;

    .card {
      box-sizing: border-box;
      width: 43.2%;
      height: 80vh;
      max-width: 330px;

      span {
        display: block;
      }

      .list {
        width: 100%;
        position: relative;
        padding-top: 56px;

        .title {
          border-bottom: 1px var(--pale-grey-two) solid;
          padding: 15px 26px 9px;
          font-size: 22px;
          font-weight: 300;
          line-height: 1.45;
          color: var(--dusk-blue);
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          background-color: var(--white);
        }

        .children {
          max-height: calc(80vh - 56px);
          overflow-x: hidden;
          @extend %scrollBar;

          .dailyReminder,
          .recentNotification,
          .recentlySearched {
            display: flex;
            flex-direction: column;
            padding: 25px 26px 14px;
            border-bottom: 1px var(--pale-grey-two) solid;

            .name {
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 1.13px;
              color: var(--cloudy-blue);
              text-transform: uppercase;
            }

            .email {
              font-size: 12px;
              line-height: 1.83;
              color: var(--dusk-blue);
            }

            .advisors {
              font-size: 12px;
              font-weight: 500;
              letter-spacing: 1.13px;
              color: var(--cloudy-blue);
            }

            .content {
              font-size: 15px;
              line-height: 1.47;
              color: var(--dark-grey-blue);
            }

            .row {
              justify-content: space-between;

              .date {
                font-size: 12px;
                line-height: 1.83;
                color: var(--cloudy-blue-two);
              }

              .open {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.5;
                color: var(--clear-blue);

                &:hover {
                  cursor: pointer;
                }
              }

              .tickbox {
                width: 30.7px;
                height: 30.7px;
                border-radius: 50%;
                display: flex;
                justify-content: center;
                align-items: center;

                &:hover {
                  cursor: pointer;
                }

                &.active {
                  background-color: var(--medium-green);
                }

                &.inactive {
                  background-color: var(--cloudy-blue);
                }

                input {
                  display: none;
                }
              }
            }
          }

          .dailyReminder {
            .email {
              margin-bottom: 5px;
            }

            .content {
              margin-bottom: 20px;
            }
          }

          .recentNotification {
            .advisors {
              margin-bottom: 11px;
            }

            .row {
              align-items: flex-end;
              margin-top: -9px;
            }
          }

          .recentlySearched {
            flex-direction: row;
            padding: 0;

            .info,
            .remove {
              display: flex;
              &:hover {
                cursor: pointer;
              }
            }

            .info {
              padding: 25px 0 25px 25px;
              flex-direction: column;
              flex: 5;

              &:hover{
                background-color: var(--clear-blue-007);
              }
            }

            .remove {
              flex: 1;
              justify-content: center;
              align-items: center;

              &:hover{
                background-color: rgba(255, 0, 0, 0.1);
              }
            }
          }
        }
      }
    }
  }
}