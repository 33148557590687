.login {
  width: 100%;
  height: 100vh;
  display: flex;

  .misc {
    width: 58.3%;
    height: 100%;
    position: relative;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    display: flex;
    align-items: flex-start;
    justify-content: center;
    padding-top: 143px;
    box-sizing: border-box;

    .logo {
      width: 58.1%;
      max-width: 519px;
    }
  }

  .form {
    width: 41.7%;
    height: 100%;
    background-color: var(--white);
    display: flex;
    justify-content: center;
    align-items: center;

    .form-holder {
      max-width: 315px;

      .title {
        display: inline-block;
        font-size: 50px;
        font-weight: 300;
        line-height: 0.64;
        color: var(--dusk-blue);
        margin-bottom: 15px;
      }

      .subtitle {
        display: inline-block;
        font-size: 15px;
        line-height: 1.47;
        color: var(--bluey-grey);
        margin-bottom: 50px;
      }

      .inputContainer {
        &:first-of-type {
          margin-bottom: 22px;
        }
        .input {
          width: 315px;
        }
      }

      .warning {
        width: 100%;
        justify-content: space-between;
        align-items: center;
        padding: 8px 0 31px;

        span {
          font-size: 15px;
          color: var(--orangey-red);
        }
      }

      .separator {
        height: 60px;
        width: 1px;
      }
    }
  }
}